import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { SplashScreen } from "./splash-screen";
import { enqueueSnackbar } from "notistack";

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  const isIdChecked = useRef(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      console.error("No widget id provided");
      if (!isIdChecked.current) {
        enqueueSnackbar("No widget id provided", { variant: "error" });
        isIdChecked.current = true;
      }
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const isDev = window.location.href.includes("widget-prototype-dev");

    const script = document.createElement("script");
    script.src = `https://openai-widget${isDev ? "-dev" : ""}.web.app/ChatComponent.bundle.js`;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = async function () {
      // Check if the chat container exists
      let chatContainer = document.getElementById("chat-container");
      // If the chat container doesn't exist, create it
      if (!chatContainer) {
        chatContainer = document.createElement("div");
        chatContainer.id = "chat-container";
        document.body.appendChild(chatContainer); // Append to the body or another specific element
      }
      // Initialize the Chat component
      try {
        if ((window as any)?.ChatComponent) {
          const widget = await (window as any)?.ChatComponent.init(id, "#chat-container");
          if (widget?.error) {
            console.error(widget.error);
            enqueueSnackbar(widget.error, { variant: "error" });
          }
        } else {
          console.error("ChatComponent is not available");
          enqueueSnackbar("Error loading widget", { variant: "error" });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  }, [id]);

  return isLoading ? <SplashScreen /> : <div />;
}

export default App;
